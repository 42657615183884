<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "jiHua",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "选择任务",
                        text:[
                            '#助手界面有"下一步任务"选项,可以选择下一个要执行的任务,当正在执行的任务停止后,会切换到下一步任务继续执行(图1)',
                            '#异常停止或者手动停止不会自动切换下一步任务'
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "举例说明1",
                        text:[
                            '#以"团练"结束后,传送到成都,然后执行"拉镖"举例',
                            '#首先设置团练的参数(图1), 开始任务',
                            '#团练任务结束后,执行传送到指定T点(如果不需要传送,直接留空),' +
                            '然后移动到指定地点(因为没有设置坐标,所以直接略过,执行下一个任务)',
                            '*注意:点击保存设置'
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                    {
                        title: "举例说明2",
                        text:[
                            '#以"拉镖"结束后,传送到蟾岛,然后执行"江湖豪杰"举例',
                            '#首先设置拉镖的参数(图1), 开始任务',
                            '#拉镖任务结束后,执行传送到指定T点(因为没有设置, 所以直接略过),' +
                            '然后移动到指定地点(坐标是蟾岛的坐标,到达位置后,执行下一个任务)',
                            '*注意:下一步执行的任务需要设置好参数(图2)',
                            '*注意:点击保存设置'
                        ],
                        img:[
                            "3.png",
                            "4.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            setTimeout(res =>{
                this.$refs.row.initData(this.list);
            },100)
        }
    }
</script>

<style scoped>

</style>
